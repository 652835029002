.custom-input-calendar {
  position: relative;
}

.input-calendar {
  height: 3em;
  width: 25em;
  padding: 0 1.5em;
  border-radius: 30px;
  border: 1px solid #c0c0c0;
  cursor: pointer;

}

.input-calendar:focus {
  outline: none;
}

.img-calendar {
  width: 1.8em;
  height: 1.8em;
  position: absolute;
  right: 20px;
  top: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .input-calendar {
    margin-top: 20px;
    height: 3.8em;
    width: 100%;
  }
  .img-calendar {
    top: 30px;
  }
  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .input-calendar {
    margin-top: 20px;
    width: 100%;
  }
  .img-calendar {
    top: 30px;
  }
  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .input-calendar {
    margin-top: 0;
    width: 22em;
  }
  .img-calendar {
    top: 10px;
  }
  .customDatePickerWidth,
  .customDatePickerWidth > div.react-datepicker-wrapper,
  .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: inherit;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .input-calendar {
    width: 100%;
  }
}
