.my-list-container-schedule {
  display: flex;
  margin: 0 auto;
}

.my-list-container-table {
  height: 30em;
  overflow: scroll;
  margin-bottom: 2em;
}
