.schedule-container {
  display: flex;
  flex-direction: column;
}

.schedule-container .schedule-header {
  display: flex;
  justify-content: center;
  margin: 7px 10px;
  align-items: center;
  flex-direction: column;
  width: auto;
  min-height: 77px;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 12px;
}
.schedule-container .schedule-header .text-container {
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0;
}

.text-container-normal {
  min-width: 160px;
}

.id-and-price {
  width: 80px;
}

.schedule-container .schedule-header .text-container span {
  text-align: center;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.schedule-container .schedule-buttons-container {
  display: flex;
  height: 57px;
}

.schedule-container .schedule-buttons-container button {
  width: 118px;
  height: 47px;
  margin: 0.3rem 0.4rem;
  border-radius: 45px;
  background: #ffce37;
  border: none;
}

.schedule-container .schedule-buttons-container .deactive-btn-user {
  background-color: #d9534f;
}

.schedule-container .schedule-buttons-container button:hover {
  opacity: 0.7;
}

.select-staff {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: #fff;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
}
