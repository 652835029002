.container-date-picker {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-btn-price {
  padding: 1em 4em;
}

.input-group-date {
  width: 30em;
  display: flex;
  flex-direction: column;
}
.btn-submit-date {
  margin-top: 1em;
  width: 10em;
  height: 3em;
  border-radius: 40px;
  color: #fff;
  background-color: #fb9444;
  border: 2px solid #fb9444;
  transition: all 0.3s;
}
.btn-submit-date:hover {
    opacity: .7;
}

.section-list {
    padding-top: 2em;
}