/*Section input*/
.section-sign-up {
  padding: 7em 7em;
  background-color: var(--main-up-keep);
  color: #fff;
}
.container-section-sign-up {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.title-section-sign-up {
  font-size: 3em;
  text-align: center;
}
.text-section-sign-up {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 35px;
  text-align: center;
}
.form {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.input-signup {
  min-width: 750px;
  height: 80px;
  border: 10px solid #c4e2ee;
  border-radius: 40px;
  background-color: #fff;
  padding-left: 60px;
}
.input-signup:focus {
  outline: none;
}

.envelope-icon-input {
  color: #c0c0c0;
  margin-right: -60px;
  margin-left: 30px;
  padding-top: 2px;
  z-index: 1;
}

.btn-signup {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fb9444;
  border-radius: 40px;
  width: 150px;
  height: 60px;
  color: #fff;
  font-weight: bold;
  border: 1px solid #fb9444;
  margin-left: -160px;
  font-size: 1em;
}

/*Media Querys*/
@media only screen and (min-width: 768px) {
  .input-signup {
    min-width: 650px !important;
  }
}

@media only screen and (min-width: 600px) {
  .section-sign-up {
    padding: 4em 2em;
  }
  .input-signup {
    min-width: 500px;
  }
  .btn-signup {
    width: 150px;
    margin-left: -160px;
  }
}

@media only screen and (max-width: 600px) {
  .section-sign-up {
    padding: 4em 1.2em;
  }
  .input-signup {
    min-width: 280px;
    border: 4px solid #c4e2ee;
    height: 60px;
  }
  .form {
    display: flex;
    flex-direction: column;
    border-top: 25px solid #c4e2ee;
    border-bottom: 10px solid #c4e2ee;
    border-left: 29px solid #c4e2ee;
    border-right: 29px solid #c4e2ee;
    border-radius: 30px;
    padding: 0 100px;
    width: 290px;
  }
  .btn-signup {
    border-radius: 40px;
    width: 280px;
    height: 60px;
    margin-left: 0;
    margin-bottom: 0;
    font-size: 1.5em;
    border: 4px solid #c4e2ee;
    align-items: center;
  }
  .envelope-icon-input {
    color: #c0c0c0;
    margin-bottom: -43px;
    margin-right: 240px;
    z-index: 1;
    width: 2em;
  }
  .envelope-btn {
    font-size: 1.5em !important;
    padding-left: 40px;
  }
  .text-in-btn {
    padding-right: 40px;
  }
}
