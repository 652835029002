.nav-item{
  margin: 0 1rem 0 1rem;
  cursor: pointer;
  position: relative;
}

.nav-link{
  color: var(--main-up-keep-secondary) !important;
  font-weight: bold;
}

.badge-item {
  position: absolute;
  top: -3px;
  right: -10px;
  background-color: rgb(255, 0, 0);
  width:22px;
  height:22px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 830px) {
  .nav-item{
    margin: 0 0.4rem;
  }
}
