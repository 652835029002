.container-title {
  background-color: var(--main-up-keep-secondary);
  display: inline-block;
  margin-bottom: 2em;
}
.figure {
  width: 34em;
  height: 0px;
  border-right: 0px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 120px solid var(--main-up-keep);
  transform: rotate(180deg);
}

.title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transform: rotate(180deg);
  height: 120px;
}

.title h1 {
  padding-right: 0.7em;
  color: #fff;
  font-weight: bold;
  font-size: 3em;
}
@media screen and (max-width: 540px) {
  .figure {
    width: 18em;
  }
  .title h1 {
    padding-right: 0.7em;
    color: #fff;
    font-weight: bold;
    font-size: 2em;
  }
}
